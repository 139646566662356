import { SecondaryPrize } from '../api/competitions'

type Competition = {
  id: string
  title: string
  description?: string
  startDateTime: Date
  endDateTime: Date
  mainPrize: string
  prizeQuantity?: number
  secondaryPrizes?: SecondaryPrize[]
  image?: string
  status: CompetitionStatus
  url?: string
  updatedAt?: string
}

export enum CompetitionStatus {
  active = 'active',
  inactive = 'inactive',
  archived = 'archived',
}

export default Competition
