import styled from 'styled-components'
import { ErrorMessage } from '../../../design-system/typography'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing.m};

  > button {
    margin-top: ${(p) => p.theme.spacing.m};
  }
`

export const InputFields = styled.div`
  display: flex;
  align-self: flex-start;
  column-gap: 4rem;
`

export const Error = styled(ErrorMessage)`
  align-self: center;
  margin-top: ${(p) => p.theme.spacing.xs};
`

export const SecondaryPrizesWrapper = styled.div`
  margin-top: 2rem;
`
