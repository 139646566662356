import React from 'react'
import ImageUploader from '../../../components/ImageUploader'
import InputField from '../../../components/InputField'
import { DangerButton } from '../../../design-system/utils'
import { PrizeWrapper } from './style'
import CheckBox from '../../../components/Checkbox'
import { CheckBoxWrapper } from '../../../components/Checkbox/style'

type CompetitionSecondaryPrizeProps = {
  id: string
  title: string | null
  description: string | null
  imageUrl: string | null
  referralsRequired: number
  update: (fieldName: string, value: string | number) => void
  deletePrize: (id: string) => void
}

function CompetitionSecondaryPrize({
  id,
  title,
  description,
  imageUrl,
  referralsRequired,
  update,
  deletePrize,
}: CompetitionSecondaryPrizeProps) {
  return (
    <PrizeWrapper>
      <ImageUploader
        setImage={(value) => update('file', value)}
        defaultImageUrl={imageUrl}
      />
      <div>
        <InputField
          placeholder="Title"
          onChangeEvent={(value) => update('title', value)}
          defaultValue={title}
        />
        <InputField
          placeholder="Description"
          onChangeEvent={(value) => update('description', value)}
          defaultValue={description}
        />
        <InputField
          type="number"
          placeholder="Referrals Required"
          onChangeEvent={(val) => update('referralsRequired', Number(val))}
          defaultValue={referralsRequired}
        />

        <DangerButton onClick={() => deletePrize(id)}>Delete</DangerButton>
      </div>
    </PrizeWrapper>
  )
}
export default CompetitionSecondaryPrize
