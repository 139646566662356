import styled from 'styled-components'

export const InputFields = styled.div`
  display: flex;
  column-gap: 4rem;
  align-self: flex-start;
`

export const PrizeWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`
