import React, { useState } from 'react'
import moment from 'moment'
import ImageUploader from '../../../components/ImageUploader'
import InputField from '../../../components/InputField'
import Modal from '../../../components/Modal'
import { Heading2Bold, Heading3Bold } from '../../../design-system/typography'
import { Button, TextButton } from '../../../design-system/utils'
import { Form, InputFields, Error, SecondaryPrizesWrapper } from './style'
import Competition, { CompetitionStatus } from '../../../types/Competition'
import competitionsApi from '../../../api/competitions'
import LabeledField from '../../../types/LabeledField'
import DropdownSelectorWrapper from '../../../components/DropdownSelectorWrapper/DropdownSelectorWrapper'
import DropdownSelector from '../../../components/DropdownSelector/DropdownSelector'
import CompetitionSecondaryPrize from '../CompetitionSecondaryPrize/CompetitionSecondaryPrize'
import { Wrapper } from '../../../components/InputField/style'

type EditCompetitionProps = {
  onClose: () => void
  onUpdate: () => void
  competition: Competition
}

export const statusOptions = [
  {
    field: CompetitionStatus.active,
    label: 'Active',
  },
  {
    field: CompetitionStatus.archived,
    label: 'Archived',
  },
  {
    field: CompetitionStatus.inactive,
    label: 'Inactive',
  },
]

function EditCompetition({
  onClose,
  onUpdate,
  competition,
}: EditCompetitionProps) {
  const [thumbnail, setThumbnail] = useState(null)
  const [title, setTitle] = useState(competition.title)
  const [description, setDescription] = useState(competition?.description)
  const [startDateTime, setStartDateTime] = useState(
    moment(competition?.startDateTime).format('YYYY-MM-DDTHH:mm:ss')
  )
  const [endDateTime, setEndDateTime] = useState(
    moment(competition?.endDateTime).format('YYYY-MM-DDTHH:mm:ss')
  )
  const [mainPrize, setMainPrize] = useState(competition?.mainPrize)
  const [prizeQuantity, setPrizeQuantity] = useState<number | string>(
    competition?.prizeQuantity
  )

  const [status, setStatus] = useState<LabeledField>(
    statusOptions?.find(({ field }) => field === competition?.status)
  )
  const [url, setUrl] = useState(competition?.url)
  const [secondaryPrizes, setSecondaryPrizes] = useState(
    competition.secondaryPrizes
  )

  const [errorMessage, setErrorMessage] = useState(null)

  const addSecondaryPrize = () => {
    setSecondaryPrizes((prevState) => [
      ...prevState,
      {
        id: `${prevState?.length + 1}`,
        file: null,
        title: null,
        description: null,
        referralsRequired: null,
      },
    ])
  }

  const submitReward = async (e) => {
    e.preventDefault()

    competitionsApi
      .editCompetition({
        file: thumbnail as File,
        title,
        description,
        startDateTime: moment(startDateTime).toISOString(),
        endDateTime: moment(endDateTime).toISOString(),
        status: status?.field,
        mainPrize,
        id: competition.id,
        prizeQuantity: Number(prizeQuantity),
        secondaryPrizes,
        url,
      })
      .then(() => {
        onUpdate()
        onClose()
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrorMessage(
            'Unable to save changes. Please make sure that all fields are filled.'
          )
        } else {
          setErrorMessage(
            err?.response?.data || 'Something went wrong. Please try again.'
          )
        }
      })
  }

  return (
    <Modal onClose={onClose} width="695px">
      <Heading2Bold>Edit Competition</Heading2Bold>
      <Form onSubmit={submitReward}>
        <InputFields>
          <ImageUploader
            setImage={setThumbnail}
            defaultImageUrl={competition.image}
          />
          <div>
            <InputField
              placeholder="Title"
              onChangeEvent={setTitle}
              defaultValue={title}
            />
            <Wrapper>
              <p>
                The title must always work with “the x Competition”. For example
                the competition title should be <b>TCS London Marathon</b> not{' '}
                <b>TCS London Marathon Entry</b>.{' '}
              </p>
            </Wrapper>
            <InputField
              placeholder="Description"
              onChangeEvent={setDescription}
              defaultValue={description}
            />
            <InputField
              placeholder="Start date"
              onChangeEvent={setStartDateTime}
              defaultValue={startDateTime}
              type="datetime-local"
            />

            <InputField
              placeholder="End date"
              onChangeEvent={setEndDateTime}
              defaultValue={endDateTime}
              type="datetime-local"
            />
            <InputField
              placeholder="Main Prize"
              onChangeEvent={setMainPrize}
              defaultValue={mainPrize}
            />
            <InputField
              placeholder="Prize Quantity"
              onChangeEvent={setPrizeQuantity}
              defaultValue={prizeQuantity}
              type="number"
            />

            <InputField
              placeholder="URL"
              onChangeEvent={setUrl}
              defaultValue={url}
            />
            <DropdownSelectorWrapper label="Status">
              <DropdownSelector
                currentSelection={status}
                options={statusOptions}
                onSelect={setStatus}
                placeholder="Select status"
              />
            </DropdownSelectorWrapper>
          </div>
        </InputFields>

        <SecondaryPrizesWrapper>
          <Heading3Bold>Secondary Prizes</Heading3Bold>

          {secondaryPrizes.map((prize) => (
            <CompetitionSecondaryPrize
              key={prize.id}
              id={prize.id}
              title={prize.title}
              description={prize.description}
              imageUrl={prize.imageUrl}
              referralsRequired={prize.referralsRequired}
              deletePrize={(id) => {
                setSecondaryPrizes((prevState) =>
                  prevState.filter((p) => p.id !== id)
                )
              }}
              update={(fieldName, value) => {
                setSecondaryPrizes((prevState) => {
                  const prizeToUpdate = prevState.find((p) => p.id === prize.id)
                  return prevState.map((p) =>
                    p.id === prize.id
                      ? { ...prizeToUpdate, [fieldName]: value }
                      : p
                  )
                })
              }}
            />
          ))}

          <TextButton secondary type="button" onClick={addSecondaryPrize}>
            + Add Secondary Prize
          </TextButton>
        </SecondaryPrizesWrapper>

        <Error>{errorMessage}</Error>
        <Button secondary type="submit">
          Edit competition
        </Button>
        <TextButton type="button" onClick={onClose}>
          Cancel
        </TextButton>
      </Form>
    </Modal>
  )
}
export default EditCompetition
