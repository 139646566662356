import axios from 'axios'

type CreateCompetition = {
  file: File
  title: string
  startDateTime: string
  endDateTime: string
  description?: string
  mainPrize: string
  status: string
  secondaryPrizes?: SecondaryPrize[]
  prizeQuantity?: number
  url?: string
}

export type SecondaryPrize = {
  file: File
  title: string
  description?: string
  referralsRequired: number
  id?: string
  imageUrl?: string
}

type UpdateCompetition = CreateCompetition & { id: string }

const getAllCompetitions = () => {
  return axios.get('/api/admin/competitions', { withCredentials: true })
}

const deleteCompetition = (id: string | number) => {
  return axios.delete(`/api/admin/competitions/${id}`, {
    withCredentials: true,
  })
}

const createCompetition = ({
  file,
  title,
  startDateTime,
  endDateTime,
  description,
  mainPrize,
  status,
  prizeQuantity,
  url,
  secondaryPrizes,
}: CreateCompetition) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('title', title)
  formData.append('startDateTime', startDateTime)
  formData.append('endDateTime', endDateTime)
  formData.append('description', description)
  formData.append('mainPrize', mainPrize)
  formData.append('status', status)
  formData.append('prizeQuantity', String(prizeQuantity))
  formData.append('url', url)

  secondaryPrizes.forEach((secondaryPrize, index) => {
    formData.append(`secondaryPrizes[${index}][file]`, secondaryPrize.file)
    formData.append(`secondaryPrizes[${index}][title]`, secondaryPrize.title)
    formData.append(
      `secondaryPrizes[${index}][description]`,
      secondaryPrize.description
    )
    formData.append(
      `secondaryPrizes[${index}][referralsRequired]`,
      String(secondaryPrize.referralsRequired)
    )
  })

  return axios.post('/api/admin/competitions', formData, {
    withCredentials: true,
  })
}

const editCompetition = ({
  id,
  file,
  title,
  startDateTime,
  endDateTime,
  description,
  mainPrize,
  status,
  secondaryPrizes,
  prizeQuantity,
  url,
}: UpdateCompetition) => {
  const formData = new FormData()
  if (file) {
    formData.append('file', file)
  }

  formData.append('title', title)
  formData.append('startDateTime', startDateTime)
  formData.append('endDateTime', endDateTime)
  formData.append('description', description)
  formData.append('mainPrize', mainPrize)
  formData.append('status', status)
  formData.append('prizeQuantity', String(prizeQuantity))
  formData.append('url', url)

  secondaryPrizes.forEach((secondaryPrize, index) => {
    formData.append(`secondaryPrizes[${index}][file]`, secondaryPrize.file)
    formData.append(`secondaryPrizes[${index}][title]`, secondaryPrize.title)
    formData.append(
      `secondaryPrizes[${index}][description]`,
      secondaryPrize.description
    )
    formData.append(
      `secondaryPrizes[${index}][referralsRequired]`,
      String(secondaryPrize.referralsRequired)
    )
    formData.append(
      `secondaryPrizes[${index}][imageUrl]`,
      String(secondaryPrize.imageUrl)
    )
  })

  return axios.put(`/api/admin/competitions/${id}`, formData, {
    withCredentials: true,
  })
}

const competitionsApi = {
  getAllCompetitions,
  deleteCompetition,
  createCompetition,
  editCompetition,
}

export default competitionsApi
